<script setup lang="ts">
import type { ContentAreaItem } from '~/content';
import { storeToRefs } from 'pinia';
import { usePageContentStore } from '~/store/pageContent';
import type { ImagesSizes } from '~/composables/useImages';
import { LazyHydrationWrapper } from 'vue3-lazy-hydration';

interface Props {
  model?: ContentAreaItem[],
  placement?: ImagesSizes,
  fullWidth?: boolean,
  articlePage?: boolean
}

withDefaults(defineProps<Props>(), {
  placement: 'bottom',
  fullWidth: false,
  articlePage: false,
});

const pageStore = usePageContentStore();
const { isEpiEdit } = storeToRefs(pageStore);

</script>

<template>
  <section>
    <LazyHydrationWrapper
      :when-visible="{ rootMargin: '50px' }"
    >
      <div
        v-for="(block, index) in model"
        :key="index"
        :data-epi-content-id="isEpiEdit ? block.contentLink.guidValue : null"
        :class="{
          'min-h-[200px] md:!min-h-[300px]':
            fullWidth &&
            (block.contentLink.expanded?.contentType[1] !== 'NicotineWarningBlock' &&
              block.contentLink.expanded?.contentType[1] !== 'QuoteBlock' &&
              block.contentLink.expanded?.contentType[1] !== 'CardBlock' &&
              block.contentLink.expanded?.contentType[1] !== 'BrandListingBlock' &&
              block.contentLink.expanded?.contentType[1] !== 'WysiwygBlock' &&
              block.contentLink.expanded?.contentType[1] !== 'CustomerServiceCardsBlock'),
        }"
      >
        <EpiserverBlock :model="block.contentLink.expanded" 
                        :placement="placement" 
                        :article-page="articlePage"
        />
      </div>
    </LazyHydrationWrapper>
  </section>
</template>
